:global {
  .slick-arrow {
    visibility: hidden;
  }
  .swiper-wrapper {
    height: 320px !important;
    @media (max-width: 575px) {
      height: 260px !important;
    }
  }
  .swiper-scrollbar-drag {
    background-color: #00e6f4 !important;
  }
  .swiper-scrollbar {
    background-color: rgba(0, 230, 244, 0.22) !important;
    bottom: 2px !important;
    left: initial !important;
    right: 15px;
    transform: rotate(90deg);
    transform-origin: bottom right;
    max-width: 400px !important;
    height: 2px !important;

    top: 240px !important;
    @media (max-width: 960px) {
      display: none;
    }
  }
  li.slick-active {
    position: relative;
    color: #00e6f4;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: #00f4e5;
      width: 2px;
    }
  }
}

.main {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  .title {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 64px;
      width: 800px;
      text-transform: uppercase;
      @media (max-width: 800px) {
        font-size: 3em;
      }
      @media (max-width: 586px) {
        font-size: 2.4em;
      }
      @media (max-width: 488px) {
        font-size: 2em;
      }
      @media (max-width: 404px) {
        font-size: 1.7em;
      }
    }
  }

  .slider_wrapper {
    .button {
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: -85px;
      z-index: 100;
    }
  }

  video {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(50%);
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 230px;
    img {
      position: relative;
      width: 137px;
      height: 137px;
    }

    >div {
      display: flex;
      flex-direction: column;
      color: #fff;
      align-items: flex-end;
      gap: 10px;

      a {
        text-decoration: none;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
      }

      a + span {
        font-size: 12px;
      }
    }
  }

  .slider {
    margin-top: 20px;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .slider_card {
      text-align: center;
      color: #fff;

      h2 {
        color: #00f4e5;
      }

      p {
        font-size: 20px;
        text-align: center;
        margin-top: 105px;
        @media (max-width: 770px) {
          font-size: 16px;
        }
        @media (max-width: 575px) {
          font-size: 15px;
          // width: 310px;
          margin-top: 63px;
        }
      }

      .slider_card_span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 60px;

        span {
          width: 800px;
          border: 1px solid rgba(0, 230, 244, 0.22);
        }
      }
    }

    .slider_dots {
      position: relative;
      list-style-type: none;
      color: white;
      width: max-content;

      li {
        cursor: pointer;
        padding-right: 15px;
        padding-bottom: 49px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        background: rgba(#00e6f4, 0.22);
      }
    }
  }

  .links {
    // position: absolute;
    // top: 25%;
    // left: 0;
    // transform-origin: left top;
    // transform: rotate(270deg) translateX(-50%);
    // z-index: 10;
    // width: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      font-size: 13px;
      text-decoration: none;
      color: #fff;
      cursor: pointer;

      &:not(:first-child) {
        // margin-left: 46px;
        cursor: pointer;
      }
    }
    // @media (max-width: 575px) {
    //   display: none;
    // }
  }
}

.span {
  margin-top: -180px;
  width: 800px;
  border: 1px solid rgba(0, 230, 244, 0.22);
}

.it1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 31px;
  @media screen and (max-width: 575px) {
    margin-top: 119px;
  }
  h2 {
    color: #00f4e5;
  }

  span {
    width: 70%;
    color: #00e6f4;
    border: 1px solid rgba(0, 230, 244, 0.22);
  }
}

.test {
  background: url("https://disk.yandex.ru/d/gLJ7bwTwK4gkHw/1%20%D0%B1%D0%BB%D0%BE%D0%BA.mp4");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 425px) {
  .main {
    header {
      > img {
        width: 105px;
        position: relative;
        right: 10px;
      }
    }
  }
}
