@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Roboto&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* font-weight: 100 !important; */
}

.container{
  /*position: relative;*/
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
}
.container_md{
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
}