.block {
  padding-top: 111px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }

  > div {
    .last_button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      bottom: 10px;
      @media screen and (max-width: 575px) {
        display: block;
        margin-left: 25px;
      }
    }
  }

  video {
    z-index: -2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(70%);
  }

  .advantages {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    height: 100%;

    .line {
      transition: 0.1s;
      position: absolute;
      z-index: -1;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 0;
      background: #00f4e5;
      @media (max-width: 575px) {
        left: 0;
      }
    }

    .adv,
    .adv_fake {
      flex-basis: 50%;
      font-weight: 500;
      font-size: 16px;
      color: rgba(#fff, 0.5);
      padding-bottom: 35px;
      transition: 0.3s;
      position: relative;
      span {
        color: rgba($color: #00f4e5, $alpha: 0.4);
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        display: block;
        background: rgba(#00e6f4, 0.4);
      }

      .decor_dot {
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        height: 9px;
        width: 9px;
        border-radius: 15px;
        background: #174c54;
      }

      &:nth-child(odd) {
        text-align: left;
        padding-left: 20px;

        &:last-child {
          &:before {
            display: none;
          }
        }
        @media (max-width: 575px) {
          text-align: left;
          padding-right: 0;
          padding-left: 20px;
        }
      }

      &:nth-child(even) {
        padding-right: 20px;
        text-align: right;
        .decor_dot {
          left: 100%;
          transform: translateX(-50%);
          @media (max-width: 575px) {
            left: 0;
          }
        }

        &::before {
          transform: translate(-50%);
          display: none;

          @media (max-width: 575px) {
            display: block;
          }
        }
        @media (max-width: 575px) {
          padding-right: 0px;
          padding-left: 20px;
          text-align: left;
        }
      }

      &.pre_active {
        & .decor_dot {
          background: #00f4e5;
        }
      }

      &.active {
        color: rgba(#fff, 1);
        span {
          color: #00e6f4;
        }

        & .decor_dot {
          height: 15px;
          width: 15px;
          background: #00f4e5;
        }
      }

      @media (max-width: 575px) {
        flex-basis: 100%;
        &.adv_fake {
          padding-bottom: 0;
        }
      }
    }
  }
}
