.modal {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  &.modal__wrapper_active {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
    padding: 0 15px;
    .modal_inner {
      position: relative;
      background: #fff;
      padding: 45px;
      border-radius: 13px;
      text-align: center;
      .close {
        cursor: pointer;
        position: absolute;
        top: -25px;
        right: -20px;
        width: 20px;
        height: 20px;
        transition: transform 500ms ease 0s;
        &:hover {
          transition: transform 500ms ease 0s;
          transform: rotate(180deg);
        }
        @media (max-width: 767px) {
          right: 0px;
        }
      }
    }
  }

  .modal__wrapper {
    position: relative;
    display: flex;
    gap: 45px;
    align-items: center;
    max-width: 700px;
    height: 415px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 13px;
    background-color: #fff;
    z-index: 100;
    .close {
      cursor: pointer;
      position: absolute;
      top: -25px;
      right: -20px;
      width: 20px;
      height: 20px;
      transition: transform 500ms ease 0s;
      &:hover {
        transition: transform 500ms ease 0s;
        transform: rotate(180deg);
      }
      @media (max-width: 767px) {
        right: 0px;
      }
    }
    .modal__left {
      background: url("../../assets/modal.png");
      width: 223px;
      height: 427px;
      gap: 30px;
      display: flex;
      justify-content: center;
      background-repeat: no-repeat;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 250px;
        gap: 25px;
        .mail {
          a {
            color: #fff;
            text-shadow: 0px 0px 81px #00e6f4;
            text-decoration: none;
            text-align: right;
            margin-bottom: 25px;
            font-size: 20px;
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            text-align: right;
          }
        }
        .phone {
          a {
            margin-bottom: 25px;
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            text-align: right;
          }
        }
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .modal__form {
      display: flex;
      flex-direction: column;
      width: 62%;
      gap: 30px;
      input {
        background: rgba(164, 192, 201, 0.19);
        border-radius: 10px;
        border: none;
        height: 45px;
        width: 100%;
        padding-left: 20px;
      }
      input:active,
      :hover,
      :focus {
        outline: 0;
        outline-offset: 0;
      }
      button {
        width: 80%;
        height: 44px;

        background: #00b1bc;
        border-radius: 57px;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .modal__right {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 65%;
      @media (max-width: 767px) {
        width: 100%;
      }
      .modal__right_top {
        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          line-height: 33px;
          margin-bottom: 7px;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: 83%;
          color: #1e2026;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        width: 74%;
        color: #839296;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    @media (max-width: 767px) {
      height: auto;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.modal {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  &.modal__wrapper_active {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
    padding: 0 15px;
    .failure_modal_inner {
      position: relative;
      background: #fff;
      padding: 45px;
      border-radius: 13px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 37%;
      @media screen and (max-width: 963px) {
        width: 55%;
      }
      @media screen and (max-width: 657px) {
        width: 79%;
      }
      @media screen and (max-width: 475px) {
        >h2{
          font-size: 20px;
        }
      }
      @media screen and (max-width: 467px) {
        width: 96%;
        > div {
          width: 102% !important;
        }
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: -25px;
        right: -20px;
        width: 20px;
        height: 20px;
        transition: transform 500ms ease 0s;
        &:hover {
          transition: transform 500ms ease 0s;
          transform: rotate(180deg);
        }
        @media (max-width: 767px) {
          right: 0px;
        }
      }
    }
  }

  .modal__wrapper {
    position: relative;
    display: flex;
    gap: 45px;
    align-items: center;
    max-width: 700px;
    height: 415px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 13px;
    background-color: #fff;
    z-index: 100;
    .close {
      cursor: pointer;
      position: absolute;
      top: -25px;
      right: -20px;
      width: 20px;
      height: 20px;
      transition: transform 500ms ease 0s;
      &:hover {
        transition: transform 500ms ease 0s;
        transform: rotate(180deg);
      }
      @media (max-width: 767px) {
        right: 0px;
      }
    }
    .modal__left {
      background: url("../../assets/modal.png");
      width: 223px;
      height: 427px;
      gap: 30px;
      display: flex;
      justify-content: center;
      background-repeat: no-repeat;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 250px;
        gap: 25px;
        .mail {
          a {
            color: #fff;
            text-shadow: 0px 0px 81px #00e6f4;
            text-decoration: none;
            text-align: right;
            margin-bottom: 25px;
            font-size: 20px;
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            text-align: right;
          }
        }
        .phone {
          a {
            margin-bottom: 25px;
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            text-align: right;
          }
        }
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .modal__form {
      display: flex;
      flex-direction: column;
      width: 62%;
      gap: 30px;
      input {
        background: rgba(164, 192, 201, 0.19);
        border-radius: 10px;
        border: none;
        height: 45px;
        width: 100%;
        padding-left: 20px;
      }
      input:active,
      :hover,
      :focus {
        outline: 0;
        outline-offset: 0;
      }
      button {
        width: 80%;
        height: 44px;

        background: #00b1bc;
        border-radius: 57px;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .modal__right {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 65%;
      @media (max-width: 767px) {
        width: 100%;
      }
      .modal__right_top {
        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          line-height: 33px;
          margin-bottom: 7px;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: 83%;
          color: #1e2026;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        width: 74%;
        color: #839296;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    @media (max-width: 767px) {
      height: auto;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
