@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Roboto:wght@100&display=swap');

.searchWrapper {
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: #00B1BC;
    font-size: 20px;
    font-weight: 500;
  }
  h1 {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 55px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
    @media screen and (max-width: 425px) {
      font-size: 29px;
    }
  }
  .cards_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 80%;
    gap: 30px;
    margin: 0 auto;
    grid-template-areas:
      "s a t"
      "s a t"
      "s a l";

    .spec {
      grid-area: s;
      box-shadow: 0px 13px 45px rgba(110, 143, 153, 0.19);
      border-radius: 13px;
      background: #fff;
      padding: 35px;
      div {
        margin-top: 32px;
        align-items: baseline;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #00b1bc;
      }
      h2 {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 6.5%;
      }
      ul {
        position: relative;
        left: 18px;
        li {
          margin-bottom: 10px;
          color: #1e2026;
        }
      }
    }
    .language {
      grid-area: a;
      box-shadow: 0px 13px 45px rgba(110, 143, 153, 0.19);
      border-radius: 13px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .language_top {
        padding: 35px;
        div {
          display: flex;
          justify-content: space-around;
          margin-top: 32px;
          gap: 39px;
          ul {
            position: relative;
            li {
              margin-bottom: 10px;
              color: #1e2026;
            }
          }
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #00b1bc;
        }
        h2 {
          margin-top: 10px;
          font-size: 24px;
          font-weight: 800;
          letter-spacing: 6.5%;
        }
      }
      .spec_find.spec_find {
        height: 230px;
        width: 100%;
        background: #323640;
        box-shadow: 0px 13px 45px rgba(110, 143, 153, 0.19);
        border-radius: 0px 0px 13px 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        p {
          font-size: 16px;
          font-weight: 400 !important;
          color: #fff;
          width: 78%;
          line-height: 18.75px;
        }
        button {
          border-radius: 57px;
          background: none;
          color: #00f4e5;
          font-size: 16px;

          background: transparent;
          border: 1px solid transparent;
          font-size: 16px;
          padding: 13px 48px;
          cursor: pointer;
          display: flex;
          transition: 0.3s;

          border-radius: 45px;
          border-color: #00f4e5;
          color: #00f4e5;
          &:hover {
            background-color: #00f4e5;
            color: #174c54;
          }
        }
      }
    }
    .techno {
      grid-area: t;
      box-shadow: 0px 13px 45px rgba(110, 143, 153, 0.19);
      border-radius: 13px;
      background: #fff;
      padding: 35px;
      div {
        margin-top: 32px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #00b1bc;
      }
      h2 {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 6.5%;
      }
      ul {
        position: relative;
        left: 18px;
        li {
          margin-bottom: 10px;
          color: #1e2026;
        }
      }
    }
    .level {
      grid-area: l;
      box-shadow: 0px 13px 45px rgba(110, 143, 153, 0.19);
      border-radius: 13px;
      background: #fff;
      padding: 35px;

      ul {
        margin-top: 30px;
        position: relative;
        left: 18px;
        li {
          margin-bottom: 10px;
          color: #1e2026;
        }
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #00b1bc;
      }
      h2 {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 6.5%;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .searchWrapper {
    .language.language {
      .spec_find {
        height: 230px;
        width: 100%;
        background: #323640;
        box-shadow: 0px 13px 45px rgb(110 143 153 / 19%);
        border-radius: 0px 0px 13px 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 15px;
        p {
          width: 88%;
        }
        button {
          padding: 13px 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .searchWrapper {
    .cards_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 80%;
      gap: 30px;
      margin: 0 auto;
      grid-template-areas:
        "s s s"
        "a a a"
        "t t t"
        "l l l";
    }
    .spec {
      div {
        display: flex;
        gap: 75px;
        align-items: center;
      }
    }
    .level {
      width: 60%;
    }
    .language.language {
      display: flex;
      flex-direction: inherit;
      .language_top {
        div {
          display: flex;
          justify-content: space-between;
          position: relative;
          left: 0;
          gap: 44px;
        }
      }

      .spec_find.spec_find {
        height: auto;
        width: 53%;
        gap: 50px;
        p {
          width: 89%;
          font-size: 16px;
        }
      }
    }
    .techno {
      div {
        ul:first-child {
          width: 200px;
        }
        display: flex;
        gap: 90px;
      }
    }
  }
}
@media (max-width: 539px) {
  .searchWrapper {
    .cards_wrapper {
      grid-template-areas:
        "s s s"
        "a a a"
        "t t t"
        "l l l";
      .level {
        width: 100%;
      }
      .language.language {
        display: flex;
        flex-direction: column;
        .language_top {
          div {
            display: flex;
            justify-content: space-between;
            position: relative;
            left: 1px;
          }
        }

        .spec_find {
          padding: 20px 0;
          height: auto;
          width: 100%;
          p{
            width: 88%;
            font-size: 16px;
          }
        }
      }
      .spec {
        div {
          display: flex;
          flex-direction: column;
          gap: 0px;
          align-items: center;
          justify-content: center;
          ul {
            width: 100%;
          }
        }
      }
      .techno {
        div {
          display: flex;
          flex-direction: column;
          gap: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .searchWrapper {
    .cards_wrapper {
      .language.language {
        .language_top {
          div {
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
