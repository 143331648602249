body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    width: 5px;
    border-bottom-right-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 230, 244, 0.4);
  }
  &::-webkit-scrollbar-thumb {
    background: #00F4E5;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn{
  background: transparent;
  border: 1px solid transparent;
  font-size: 16px;
  padding: 13px 48px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  &.btn_v1{
    border-radius: 45px;
    border-color: #00F4E5;
    color: #00F4E5;
    &:hover{
      background-color: #00F4E5;
      color: #174C54;
    }
  }
}
.blue{
  color: #00f4e5;
}
.text-center{
  text-align: center;
}
.h2{
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  @media(max-width: 768px){
    font-size: 36px;
  }
  @media(max-width: 575px){
    font-size: 32px;
  }
}
.subtitle{
  font-weight: 500;
  font-size: 20px;
  color: #00f4e5;
  text-shadow: 0px 0px 50px #00F4E5;
}

@media screen and (max-width: 425px) {
  h2{
    font-size: 42px;

  @media(max-width: 575px){
    font-size: 14px;

  }
}
}