.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 65px 0 65px 0;
  h1 {
    font-size: 30px;
    font-weight: 800;
    color: #1e2026;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    align-items: center;
    color: #1e2026;
    text-align: center;
    width: 83%;
    font-family: 'Roboto', sans-serif;
  }
  .companies_wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    img {
      margin-bottom: 20px;
      width: 150px;
      height: 45px
    }
    :global {
      .swiper {
        height: 255px;
      }
      .swiper-slide.swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68%;
      }
    }
  }
  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      height: 100%;
    }
  }
}
