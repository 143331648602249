@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&family=Roboto:wght@700&display=swap");

.allWrapper {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .logos {
    width: 750px;
    position: absolute;
    @media screen and (max-width: 1047px) {
      width: 700px;
    }
    @media screen and (max-width: 927px) {
      width: 650px;
    }
    @media screen and (max-width: 843px) {
      width: 507px;
    }
    @media screen and (max-width: 575px) {
      width: 396px;
    }
  }
  @media screen and (max-width: 575px) {
    
    width: 40%;
  }
  .wrapper {
    background: url("../../assets/whoAreWe.png");
    width: 100%;
    height: 750px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;


    .WhoAreWe {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 43%;

      h1 {
        font-size: 48px;
        color: #1e2026;
        font-weight: 800;
        margin-bottom: 20px;
        @media screen and (max-width: 536px) {
          margin-bottom: 0;
          font-size: 44px;
        }
      }

      p {
        color: #1e2026;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        width: 64%;

        @media screen and (max-width: 536px) {
          color: #1e2026;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          width: 113%;
        }
      }

      span {
        color: #00b1bc;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        @media screen and (max-width: 536px) {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 785px) {
  .allWrapper {
    width: 100%;
    .wrapper {
      .WhoAreWe {
        width: 80%;
      }
    }
  }
}
