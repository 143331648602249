.footer_wrapper {
  background: url("../../assets/footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 32px;

  .footer_bottom {
    width: 34%;
    position: relative;
    left: 110px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    p {
      color: #fff;
      font-weight: 400;
      font-size: 10px;
    }
    a {
      color: #00b1bc;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .footer {
    width: 86%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    p {
      color: #fff;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      @media screen and (max-width: 1200px) {
        font-size: 11px;
      }
      @media screen and (max-width: 900px) {
        width: 290px;
      }
    }
    img {
      width: 137px;
      height: 137px;
      margin-right: 35px;
      position: relative;
      right: 20px;
    }
  }

  .footer_right {
    .contacts {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      font-size: 18px;
    }
    .contacts_mail {
      a {
        color: #fff;
        text-decoration: none;
      }
      p {
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        text-align: right;
      }
    }
    .contacts_phoneNum {
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        color: #fff;
      }
      p {
        font-size: 10px;
        font-weight: 400;
        text-align: right;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer.footer {
    width: 86%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: normal;
    flex-direction: column;
    .footer_left {
      width: 90%;
      display: flex;
      justify-content: initial;
    }
    .footer_right.footer_right {
      align-self: end;
    }
  }
  .footer_bottom.footer_bottom {
    position: relative;
    left: 57px;
    bottom: 34px;
    width: 44%;
  }
}

@media screen and (max-width: 580px) {
  .footer {
    .footer_left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 101%;
    }
    .footer_right.footer_right {
      align-self: auto !important;
      margin-top: 32px;
    }
  }
  .footer_bottom.footer_bottom {
    position: relative;
    left: 28px;
    bottom: 6px;
    width: 87%;
    margin-top: 32px;
  }
}
