.main {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 115px;
  video {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(32%);
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .progressBarWrapper {
    margin-top: 48px;
    position: relative;
    z-index: 1;
    overflow-x: auto;
    height: auto;
    > div {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 275px;
      &::before {
        content: "";
        position: absolute;
        width: 300%;
        left: -50%;
        top: 45.9%;

        height: 1px;
        background: rgba(0, 230, 244, 0.22);
      }
    }
    .steps_wrapper {
      margin-top: 150px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      width: 82%;
      gap: 35px;
      .steps_title {
        display: flex;
        width: 100%;
        height: 75px;
        justify-content: space-between;
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          width: 20%;
        }
        > p:last-child {
          width: 105px;
        }
      }
      .steps {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        align-items: flex-start;
        position: relative;
        .progressBar {
          transition: 0.1s;
          position: absolute;
          z-index: -1;
          bottom: 116px;
          height: 1px;
          width: 5%;
          background-color: #00e6f4;
        }
        p {
          font-size: 14px;
          font-weight: 300;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          .line {
            position: relative;
            left: 17px;
            height: 54px;
            border: 2px aliceblue dashed;
            visibility: hidden;
            border: 1px dashed #00f4e5;
          }
          .line_active {
            position: relative;
            left: 17px;
            height: 54px;
            border: 2px aliceblue dashed;
            visibility: visible;
            border: 1px dashed #00f4e5;
          }
          .solve {
            color: #00f4e5;
            font-weight: 600;
            text-shadow: 0px 4px 25px #00f4e5;
            text-align: right;
            font-size: 14px;
            position: relative;
            left: -78px;
          }
          .notResolve {
            color: grey;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            left: -78px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    .progressBarWrapper {
      > div {
        width: 150%;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .main {
    gap: 80px;
    .progressBarWrapper {
      margin-left: 22px;
      .steps_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        .steps {
          display: flex;
          flex-direction: column;
          gap: 35px;
          div {
            .line {
              display: none;
            }
            .line_active {
              display: none;
            }
          }
          .progressBar {
            top: 0;
            bottom: 0;
            left: 15px;
          }
          > div:last-child {
            flex-direction: row;
            align-items: center;
            div {
              margin-bottom: 20px;
              p {
                left: 10px;
                top: 11px;
              }
              img {
                display: none;
              }
              div {
                display: none;
              }
            }
          }
        }
        .steps_title {
          display: flex;
          flex-direction: column;
          height: 100%;
          position: absolute;
          left: 57px;
          gap: 36px;
          @media screen and (max-width: 340px) {
            gap: 35px;
            > p:first-child {
              width: 85%;
            }
            > p:nth-of-type(2) {
              position: relative;
              top: 3px;
            }
            > p:nth-of-type(4) {
              position: relative;
              top: -4px;
            }
          }
          p {
            width: 82%;
          }

          > p:last-child {
            height: 100px;
            width: 1px;
          }
        }
      }
      > div {
        width: 100%;
        height: fit-content;
        &::before {
          content: none;
        }
      }
    }
  }
}
